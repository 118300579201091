import React from "react";
import { Link } from "react-router-dom";
import { LuPhoneCall } from "react-icons/lu";

const SimpleLink = ({ props }) => {
  const { title, linkText, linkAria, imgSrc, imgAlt } = props;

  return (
    <section className="simple-link">
      <img className="image" src={`img/${imgSrc}`} alt={imgAlt} />
      <h1 className="title">{title}</h1>
      <Link className="link" to="tel:510405801" aria-label={linkAria}>
        {linkText}
        <span>
          <LuPhoneCall />
        </span>
      </Link>
    </section>
  );
};

export default SimpleLink;
