import React from "react";

const Welcome = ({ props }) => {
  const { one, two } = props;

  return (
    <article className="welcome">
      <section className="one">
        <h1 className="title">
          {one.titleA}
          <br />
          {one.titleB}
        </h1>
        <img className="image" src={`img/${one.imgSrc}`} alt={one.imgAlt} />
      </section>
      <section className="two">
        <p className="text">{two.text}</p>
        <img className="image" src={`img/${two.imgSrc}`} alt={two.imgAlt} />
      </section>
    </article>
  );
};

export default Welcome;
