import React from "react";

const SimpleArticleV1 = ({ props }) => {
  const { className, title, imgSrc, imgAlt, text } = props;

  return (
    <article className={`article ${className}`}>
      <h1 className="title">
        {title}
        <img className="image" src={`img/${imgSrc}`} alt={imgAlt} />
      </h1>
      <p className="text">{text}</p>
    </article>
  );
};

const SimpleArticleV2 = ({ props }) => {
  const { title, text, imgSrc, imgAlt } = props;

  return (
    <article className="article">
      <h1 className="title">{title}</h1>
      <div className="container">
        <p className="text">{text}</p>
        <img className="image" src={`img/${imgSrc}`} alt={imgAlt} />
      </div>
    </article>
  );
};

export { SimpleArticleV1, SimpleArticleV2 };
