import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineReadMore } from "react-icons/md";
import { Carousel } from "nuka-carousel";

const SlideShow = ({ props }) => {
  const { slide } = props;

  return (
    <div className="slide-show">
      <Carousel
        autoplay={true}
        wrapAround={true}
        autoplayInterval={6969}
        withoutControls={false}
        slidesToShow={1}
      >
        {Object.keys(slide).map((index) => (
          <section className="slide" key={index}>
            <img
              className="image"
              src={`img/${slide[index].imgSrc}`}
              alt={slide[index].imgAlt}
            />
            <div className="description">
              <h1 className="title">{slide[index].title}</h1>
              <p className="text">{slide[index].text}</p>
            </div>
          </section>
        ))}
      </Carousel>
      <Link to="/work">
        <button className="button">
          Czytaj dalej
          <span className="icon">
            <MdOutlineReadMore />
          </span>
        </button>
      </Link>
    </div>
  );
};

export default SlideShow;
