import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Header from "./layouts/header.jsx";
import ContactForm from "./layouts/contact-form.jsx";
import Welcome from "./layouts/welcome.jsx";
import SimpleText from "./layouts/simple-text.jsx";
import { SimpleArticleV1, SimpleArticleV2 } from "./layouts/simple-article.jsx";
import Info from "./layouts/info.jsx";
import Article from "./layouts/article.jsx";
import SlideShow from "./layouts/slide-show.jsx";
import SimpleLink from "./layouts/simple-link.jsx";
import JobOffer from "./layouts/job-offer.jsx";
import Footer from "./layouts/footer.jsx";

import DataHome from "./data/home.json";
import DataServices from "./data/services.json";
import DataWork from "./data/work.json";
import DataAbout from "./data/about.json";
import DataContact from "./data/contact.json";

import "./scss/header.scss";
import "./scss/home.scss";
import "./scss/services.scss";
import "./scss/work.scss";
import "./scss/about.scss"
import "./scss/contact.scss";
import "./scss/footer.scss";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

const Home = ({ data }) => {
  const { welcome, toWork, slideShow, toContact } = data;

  return (
    <main className="home">
      <Welcome props={welcome} />
      <SimpleText props={toWork} />
      <SlideShow props={slideShow} />
      <SimpleLink props={toContact} />
    </main>
  );
};

const Services = ({ data }) => {
  const { recruitment, jobInEurope, rentBed, trading } = data;

  return (
    <main className="services">
      <SimpleArticleV1 props={recruitment} />
      <SimpleArticleV1 props={jobInEurope} />
      <SimpleArticleV1 props={rentBed} />
      <Article props={trading} />
    </main>
  );
};

const Work = ({ data }) => {
  const { jobOffer } = data;
  return (
    <main className="work">
      <JobOffer props={jobOffer} />
    </main>
  );
};

const About = ({ data }) => {
  const { weAre, ourMission, ourValues, whyUs } = data;

  return (
    <main className="about">
      <SimpleArticleV1 props={weAre} />
      <SimpleArticleV1 props={ourMission} />
      <Info props={ourValues} />
      <Info props={whyUs} />
    </main>
  );
};

const Contact = ({ data }) => {
  const { joinUs } = data;
  return (
    <main className="contact">
      <SimpleArticleV2 props={joinUs} />
      <ContactForm />
    </main>
  );
};

const NotFound = () => {
  return (
    <main>
      <p>404 Not Found</p>
    </main>
  );
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Header />
    <Routes>
      <Route path="/" element={<Home data={DataHome} />} />
      <Route path="/about" element={<About data={DataAbout} />} />
      <Route path="/contact" element={<Contact data={DataContact} />} />
      <Route path="/services" element={<Services data={DataServices} />} />
      <Route path="/work" element={<Work data={DataWork} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);
