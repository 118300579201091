import React from "react";
import {
  FaCircleRight,
  FaMoneyBillWave,
  FaHouseFlag,
  FaClock,
  FaLocationDot,
} from "react-icons/fa6";

const JobOffer = ({ props }) => {
  return (
    <>
      {Object.keys(props).map((index) => (
        <section className="job-offer" key={index + 1}>
          <h1 className="title">
            <span className="arrow">
              <FaCircleRight />
            </span>
            {props[index].title}
            <img
              className="image"
              src={`img/${props[index].imgSrc}`}
              alt={props[index].imgAlt}
            />
          </h1>
          <p className="description">{props[index].description}</p>
          <ul className="list">
            <li className="item">
              <span className="salary">
                <FaMoneyBillWave />
              </span>
              {props[index].salary}
            </li>
            <li className="item">
              <span className="accomodation">
                <FaHouseFlag />
              </span>
              {props[index].accomodation}
            </li>
            <li className="item">
              <span className="time">
                <FaClock />
              </span>
              {props[index].time}
            </li>
            <li className="item">
              <span className="location">
                <FaLocationDot />
              </span>
              {props[index].location}
            </li>
          </ul>
        </section>
      ))}
    </>
  );
};

export default JobOffer;
