import React from "react";

const SimpleText = ({ props }) => {
  const { text, imgSrc, imgAlt } = props;

  return (
    <article className="article">
      <p className="text">{text}</p>
      <img className="image" src={`img/${imgSrc}`} alt={imgAlt} />
    </article>
  );
};

export default SimpleText;
