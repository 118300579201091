import React from "react";
import { IoArrowRedo } from "react-icons/io5";

const Info = ({ props }) => {
  const { title, imgSrc, imgAlt, container } = props;

  return (
    <article className={`info ${props}`}>
      <h1 className="title">
        {title}
        <img className={`image ${props}`} src={`img/${imgSrc}`} alt={imgAlt} />
      </h1>
      {Object.keys(container).map((index) => (
        <div className="container" key={index + 1}>
          <h2 className="sub-title">
            <IoArrowRedo />
            {container[index].subTitle}
          </h2>
          <p className="text">{container[index].subText}</p>
        </div>
      ))}
    </article>
  );
};

export default Info;
