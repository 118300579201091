import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { ImHome } from "react-icons/im";

const Header = () => {
  const Logo = () => {
    return (
      <>
        <section className="company">
          <img className="logo" src="img/logo.webp" alt="Logo" />
          <h1 className="name">KLAUDIA INTERNATIONAL</h1>
        </section>
      </>
    );
  };

  const Navigation = () => {
    const location = useLocation();

    const NavItem = ({ to, title, isActive }) => {
      const indicatorSpring = useSpring({
        left: isActive ? "100%" : "0%",
        height: isActive ? "100%" : "0%",
        config: { mass: 1, tension: 100, friction: 10 },
      });

      return (
        <animated.div
          className={`indicator ${isActive ? "active" : ""}`}
          style={indicatorSpring}
        >
          <li className="item">
            <Link
              className="link"
              to={to}
              aria-label={`Przejdź do ${title} strony`}
            >
              {title}
            </Link>
          </li>
        </animated.div>
      );
    };

    return (
      <nav className="navigation">
        <NavItem
          title={<ImHome style={{ fontSize: "0.999rem" }} />}
          to="/"
          isActive={location.pathname === "/"}
        />
        <NavItem
          title="Usługi"
          to="/services"
          isActive={location.pathname === "/services"}
        />
        <NavItem
          title="Praca"
          to="/work"
          isActive={location.pathname === "/work"}
        />
        <NavItem
          title="O Nas"
          to="/about"
          isActive={location.pathname === "/about"}
        />
        <NavItem
          title="Kontakt"
          to="/contact"
          isActive={location.pathname === "/contact"}
        />
      </nav>
    );
  };

  return (
    <header>
      <Logo />
      <Navigation />
    </header>
  );
};

export default Header;
