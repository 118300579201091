import React from "react";

const Article = ({ props }) => {
  const { className, title, imgSrc, imgAlt, intro, subTitle, subText, ending } =
    props;

  return (
    <article className={`article ${className}`}>
      <h1 className="title">{title}</h1>
      <img className="image" src={`img/${imgSrc}`} alt={imgAlt} />
      <p className="intro">{intro}</p>
      <h2 className="sub-title">{subTitle}</h2>
      {Object.keys(subText).map((index) => (
        <p className="sub-text" key={index + 1}>
          <h3 className="product-title">{subText[index].strong}</h3>
          {subText[index].regular}
        </p>
      ))}
      <p className="ending">{ending}</p>
    </article>
  );
};

export default Article;
