import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <Link
        to="https://www.facebook.com/share/BjHA53RsfKUUApgp/"
        className="social"
        aria-label="Przejdź do Facebook"
      >
        <FaFacebookSquare />
      </Link>
      <section className="content">
        <h1 className="title">INFORMACJE KONTAKTOWE</h1>
        <p className="address">
          KLAUDIA INTERNATIONAL PROSTA SPÓŁKA AKCYJNA <br />
          UL. PUŁAWSKA 12 / 3, 02-512 WARSZAWA
        </p>
        <p className="info">
          NIP: 9542852738
          <br />
          KRS: 0001020952
          <br />
          REGON: 524764602
          <br />
          Numer telefonu: +48 510 405 801
          <br />
          E-mail: klaudiainternational@gmail.com
        </p>
      </section>
      <p className="copyright">
        &copy; 2024 Klaudia International PSA. Wszelkie Prawa Zastrzeżone
      </p>
    </footer>
  );
};

export default Footer;
